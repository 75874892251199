.switcher {
  display: flex;
  padding: 0;
  font-family: @basic-font;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  position: absolute;
  top: 10px;
  transition: width 0.5s;
  z-index: 1;
  border: 0;
  border-radius: 0 @border-radius-base @border-radius-base 0;
  &:hover {
    width: 150px;
    background: white;
    border: 2px @blue solid;
    color: @blue;
    .text-wrapper {
      display: block;
      transition: left 0.5s;
      left: 0;
      position: relative;
    }
  }

  .flag-wrapper {
    width: 30px;
    margin: auto 10px;
    z-index: 3;
  }

  .text-wrapper {
    margin: auto;
    position: absolute;
    left: -100px;
    z-index: 2;
  }
}
