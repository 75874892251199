.menu {
  @media screen and (max-width: @breakpoint-lg) {
    display: none;
  }
  display: block;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 20;
  &-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: @dark-blue;
    .menu-link {
      @media screen and (max-width: @breakpoint-xxl) and (min-width: calc(@breakpoint-xl + 1px)) {
        font-size: @font-size-lg-xxs;
      }
      @media screen and (max-width: @breakpoint-xl) and (min-width: calc(@breakpoint-lg + 1px)) {
        font-size: @font-size-base;
      }
      padding: 5px 0;
      text-align: center;
      letter-spacing: 5px;
      color: @white;
      font-family: 'adam';
      font-size: @font-size-lg-sm;
      &:hover {
        transition: 0.2s linear;
        color: @grey;
      }
      &-active {
        color: @grey;
        font-weight: @font-weight-semi-bold;
      }
    }
  }
}

.menu-overlay {
  @media screen and (max-width: @breakpoint-lg) {
    display: block;
  }
  display: none;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
  &-open {
    width: 100%;
  }
  &-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
  }
  &-open-btn {
    background: rgba(0, 0, 0, 0.4);
    border-top-left-radius: @border-radius-md;
    border-bottom-left-radius: @border-radius-md;
    position: fixed;
    z-index: 50;
    top: 10px;
    right: 0;
  }
  &-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    .menu-link {
      display: block;
      padding: 5px 0;
      letter-spacing: 5px;
      color: white;
      font-family: 'adam';
      font-size: @font-size-lg-sm;
    }
  }
}

.menu-icon {
  margin: 15px;
}
