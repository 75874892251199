.download {
  align-items: center;
  font-family: @basic-font;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  text-transform: uppercase;
  font-size: @font-size-lg;

  > i {
    font-size: 20px;
  }

  &-primary {
    background: @blue;
    color: @white;
    width: 200px;
    border: 0;
    transition: 0.2s ease-in-out;
    &:hover {
      color: @white;
      background: darken(@blue, 15%);
    }
  }
}

.profile {
  &-container {
    background-color: transparent;
    @media screen and (max-width: @breakpoint-xl) {
      width: 300px;
      height: 320px;
    }
    @media screen and (max-width: @breakpoint-md) {
      margin: auto;
    }
    width: 410px;
    height: 440px;
    perspective: 1000px;
    &:hover &-inner {
      transform: rotateY(180deg);
    }
    &-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.7s;
      transform-style: preserve-3d;
      &-front,
      &-back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
      }
      &-back {
        background-color: @dark-blue;
        color: @white;
        transform: rotateY(180deg);
      }
    }
  }
  &-text {
    @media screen and (max-width: @breakpoint-xl) {
      font-size: @font-size-xs;
    }
    font-size: @font-size-lg;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
  }
}
