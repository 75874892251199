@font-face {
  font-family: 'adam';
  src: url('./fonts/Adam-Medium.ttf');
}
@import url('https://fonts.googleapis.com/css?family=Montserrat:ital,wght@0,100;0,400;0,700;0,900;1,100&display=swap');

@basic-font: 'Montserrat', sans-serif;
@text-color: rgba(0, 0, 0, 0.85);
@font-size-base: 1rem; //17px
@font-size-page-title: 7rem; //112px
@font-size-page-title-lg: 5rem; //112px
@font-size-page-title-xs: 2rem; //112px
@font-size-lg-xxl: 2.471rem; //42px
@font-size-lg-xl: 2.118rem; //36px
@font-size-lg-sm: 1.824rem; //31px
@font-size-lg-xs: 1.588rem; //27px
@font-size-lg-xxs: 1.294rem; //22px
@font-size-lg: 1.118rem; //19px
@font-size-sm: 0.8824rem; //15px
@font-size-xs: 0.7647rem; //13px
@font-size-xxs: 0.6471rem; //11px

@font-weight-light: 300;
@font-weight-base: 400;
@font-weight-semi-bold: 600;
@font-weight-bold: 700;
@font-weight-extra-bold: 900;

html {
  font-size: @font-size-base;
}
a {
  color: @blue;
  &:hover {
    color: @dark-blue;
  }
}
h1 {
  @media screen and (max-width: @breakpoint-xxl) and (min-width: @breakpoint-md) {
    font-size: @font-size-page-title-lg;
    letter-spacing: 10px;
  }
  @media screen and (max-width: calc(@breakpoint-md - 1px)) {
    font-size: @font-size-page-title-xs;
    letter-spacing: 0px;
  }
  font-family: 'adam';
  color: @fadein-blue;
  font-size: @font-size-page-title;
  margin: 0;
  letter-spacing: 20px;
}
h2 {
  font-family: @basic-font;
  color: @blue;
  font-size: @font-size-lg-xxl;
  font-weight: @font-weight-base;
  line-height: 45px;
  letter-spacing: 5px;
  margin: 0;
}
h3 {
  font-size: @font-size-lg-sm;
  font-weight: @font-weight-bold;
  line-height: 39px;
  margin: 0;
}
h4 {
  font-size: @font-size-lg-xs;
  font-weight: @font-weight-semi-bold;
  line-height: 36px;
  margin: 0;
}
h5 {
  text-align: justify;
  font-size: @font-size-lg-xxs;
  line-height: 36px;
  margin: 0;
}
