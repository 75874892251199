.step-bar {
  background-image: linear-gradient(0deg, @dark-blue, @blue);
  width: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  align-items: center;
  z-index: 0;
  border-radius: @border-radius-lg;
  @media screen and (max-width: @breakpoint-md) {
    display: none;
  }
  .step {
    &:hover .step-description-wrapper,
    &:hover .step-date {
      transform: scale(1.05);
    }
    &:hover .step-description-wrapper {
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    }
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    z-index: 0;
    width: 1465px;
    margin: 20px 0;
    @media screen and (max-width: @breakpoint-xxl) and (min-width: calc(@breakpoint-lg - 1px)) {
      width: 900px;
    }
    @media screen and (max-width: @breakpoint-lg) and (min-width: calc(@breakpoint-md - 1px)) {
      width: 700px;
    }
    @media screen and (max-width: @breakpoint-md) and (min-width: calc(@breakpoint-sm - 1px)) {
      width: 500px;
    }
    &-index {
      width: 30px;
      height: 30px;
      background-color: transparent;
      border: 5px solid @background-color-2;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-description {
      width: 50%;
      &-wrapper {
        transition: 0.3s ease-in-out;
        background-color: @white;
        padding: 5px 25px 15px;
      }
      &-left {
        @media screen and (max-width: @breakpoint-md) and (min-width: calc(@breakpoint-sm - 1px)) {
          padding-right: 10px;
        }
        padding-right: 25px;
        text-align: right;
        .step-description-wrapper {
          transform-origin: right center;
        }
      }
      &-right {
        @media screen and (max-width: @breakpoint-md) and (min-width: calc(@breakpoint-sm - 1px)) {
          padding-left: 10px;
        }
        padding-left: 25px;
        text-align: left;

        .step-description-wrapper {
          transform-origin: left center;
        }
      }
      .step-info-left {
        > div {
          justify-content: flex-end;
        }
      }
      .step-info-right {
        > div {
          justify-content: flex-start;
        }
      }
      .step-title {
        font-size: @font-size-lg-xxl;
        font-weight: @font-weight-bold;
      }
      .step-subtitle {
        font-size: @font-size-lg;
        color: @blue;
      }
      .step-place {
        font-size: @font-size-base;
        font-style: italic;
      }
      .step-external-link {
        font-size: @font-size-base;
        text-decoration: underline;
        color: @blue;
      }
    }
    &-date {
      transition: 0.3s ease-in-out;
      width: 50%;
      &-left {
        transform-origin: right center;
        > div {
          @media screen and (max-width: @breakpoint-md) and (min-width: calc(@breakpoint-sm - 1px)) {
            padding-right: 10px;
          }
          padding-right: 25px;
          display: flex;
          justify-content: flex-end;
        }
      }
      &-right {
        transform-origin: left center;
        > div {
          @media screen and (max-width: @breakpoint-md) and (min-width: calc(@breakpoint-sm - 1px)) {
            padding-left: 10px;
          }
          padding-left: 25px;
          display: flex;
          justify-content: flex-start;
        }
      }
      &-wrapper {
        @media screen and (max-width: @breakpoint-md) and (min-width: calc(@breakpoint-sm - 1px)) {
          font-size: @font-size-xs;
        }
        background-color: @blue;
        width: max-content;
        padding: 10px;
        color: white;
        position: relative;
        top: -8px;
      }
    }
  }
}

.step-bar-minimal {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
  margin-bottom: 20px;
  width: 100%;
  @media screen and (max-width: @breakpoint-md) {
    display: flex;
  }
  &:hover .step-bar-minimal-info,
  &:hover .step-bar-minimal-date {
    transform: scale(1.05);
  }
  &:hover .step-bar-minimal-info {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }
  &-date {
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 15px;
    height: 100%;
    width: 100%;
    &-wrapper {
      text-align: center;
      width: 100%;
      background-color: @blue;
      padding: 20px 10px;
      color: white;
    }
  }

  &-info {
    transition: 0.3s ease-in-out;
    width: 100%;
    > div {
      background-color: @white;
      padding: 5px 25px 15px;
    }
    &-title {
      font-size: @font-size-lg-xxs;
      font-weight: @font-weight-bold;
    }
    &-subtitle {
      font-size: @font-size-lg;
      color: @blue;
    }
    &-place {
      font-size: @font-size-base;
      font-style: italic;
    }
  }
}

.arrow {
  width: 0;
  height: 0;
  &-left {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid @blue;
    margin: 0 -3px;
  }
  &-right {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid @blue;
    margin: 0 -3px;
  }
  &-up {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid @blue;
    margin: -3px auto;
  }
  &-down {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid @blue;
    margin: -3px auto;
  }
}
