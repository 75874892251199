.home-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('../../images/img/home_background.jpg');
  background-size: cover;
  background-attachment: fixed;
  .logo-wrapper {
    overflow: clip visible;
    position: absolute;
    top: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
    margin-top: auto;
    animation: home-icon 1.5s linear infinite;
    i {
      cursor: pointer;
    }
  }
}

@keyframes home-icon {
  0% {
    padding-bottom: 15px;
  }
  50% {
    padding-bottom: 30px;
  }
  100% {
    padding-bottom: 15px;
  }
}

.contact-network {
  transition: 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
}
