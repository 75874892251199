.year-step {
  &:hover &-info-wrapper,
  &:hover &-description-wrapper {
    transform: scale(1.05);
  }
  &:hover &-info,
  &:hover &-description {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }
  @media screen and (max-width: @breakpoint-sm) {
    display: none;
  }
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 10px 0;
  &-info {
    transition: 0.3s ease-in-out;
    &-wrapper {
      transform-origin: left center;
      transition: 0.3s ease-in-out;
      width: 40%;
    }
    padding: 10px;
    background-color: white;
    text-align: right;
    &-title {
      font-size: @font-size-lg-xxs;
      font-weight: @font-weight-bold;
    }
    &-place {
      font-size: @font-size-base;
      font-style: italic;
    }
  }
  &-date {
    display: flex;
    align-items: center;
    height: 100%;
    &-wrapper {
      background-color: @blue;
      width: max-content;
      padding: 20px 10px;
      color: white;
    }
  }
  &-description {
    transition: 0.3s ease-in-out;
    &-wrapper {
      transform-origin: right center;
      transition: 0.3s ease-in-out;
      width: 40%;
    }
    background-color: white;
    padding: 10px;
  }
}

.year-step-minimal {
  @media screen and (max-width: @breakpoint-sm) {
    display: flex;
  }
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  margin: 10px 0;
  &:hover .year-step-minimal-info,
  &:hover .year-step-minimal-description {
    transform: scale(1.05);
  }
  &:hover .year-step-minimal-info {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }
  &-info {
    transition: 0.3s ease-in-out;
    width: 100%;
    > div {
      background-color: white;
      padding: 10px;
    }
    text-align: right;
    &-title {
      font-size: @font-size-lg-xxs;
      font-weight: @font-weight-bold;
    }
    &-place {
      font-size: @font-size-base;
      font-style: italic;
    }
  }
  &-date {
    display: flex;
    margin: 15px 0;
    align-items: center;
    flex-direction: column;
    height: 100%;
    &-wrapper {
      background-color: @blue;
      width: max-content;
      padding: 20px 10px;
      color: white;
    }
  }
  &-description {
    transition: 0.3s ease-in-out;
    width: 100%;
    > div {
      background-color: white;
      padding: 10px;
    }
  }
}
