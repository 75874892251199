.footer {
  background-color: @blue;

  &-copyright {
    padding-bottom: 30px;
    text-align: center;
    color: @white;
    &-link {
      color: @white;
      &:hover {
        color: @grey;
      }
    }
  }
}
