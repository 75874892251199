@import url('https://fonts.googleapis.com/css?family=Montserrat:ital,wght@0,100;0,400;0,700;0,900;1,100&display=swap');
body {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}
ul {
  padding: 0;
}
img {
  width: 100%;
}
.page-content {
  padding: 0 200px 50px 200px;
  font-size: 1.118rem;
  font-family: 'Montserrat', sans-serif;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 576px) {
  .page-content {
    padding: 0 25px 50px 25px;
  }
}
@media screen and (max-width: 1200px) and (min-width: calc(576px + 1px)) {
  .page-content {
    padding: 0 50px 50px 50px;
  }
}
.page-content-0 {
  background: #fff;
}
.page-content-1 {
  background: #f2f2f2;
}
.page-content section {
  padding: 1.333rem 0;
  width: 100%;
}
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #184a73;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #21659d;
}
@font-face {
  font-family: 'adam';
  src: url('../base/fonts/Adam-Medium.ttf');
}
html {
  font-size: 1rem;
}
a {
  color: #2e8cd9;
}
a:hover {
  color: #184a73;
}
h1 {
  font-family: 'adam';
  color: rgba(46, 140, 217, 0.2);
  font-size: 7rem;
  margin: 0;
  letter-spacing: 20px;
}
@media screen and (max-width: 1600px) and (min-width: 768px) {
  h1 {
    font-size: 5rem;
    letter-spacing: 10px;
  }
}
@media screen and (max-width: calc(768px - 1px)) {
  h1 {
    font-size: 2rem;
    letter-spacing: 0px;
  }
}
h2 {
  font-family: 'Montserrat', sans-serif;
  color: #2e8cd9;
  font-size: 2.471rem;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 5px;
  margin: 0;
}
h3 {
  font-size: 1.824rem;
  font-weight: 700;
  line-height: 39px;
  margin: 0;
}
h4 {
  font-size: 1.588rem;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
}
h5 {
  text-align: justify;
  font-size: 1.294rem;
  line-height: 36px;
  margin: 0;
}
.footer {
  background-color: #2e8cd9;
}
.footer-copyright {
  padding-bottom: 30px;
  text-align: center;
  color: #fff;
}
.footer-copyright-link {
  color: #fff;
}
.footer-copyright-link:hover {
  color: #9a9a9a;
}
.home-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('../images/img/home_background.jpg');
  background-size: cover;
  background-attachment: fixed;
}
.home-container .logo-wrapper {
  overflow: clip visible;
  position: absolute;
  top: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.home-container .icon-wrapper {
  display: flex;
  justify-content: center;
  margin-top: auto;
  animation: home-icon 1.5s linear infinite;
}
.home-container .icon-wrapper i {
  cursor: pointer;
}
@keyframes home-icon {
  0% {
    padding-bottom: 15px;
  }
  50% {
    padding-bottom: 30px;
  }
  100% {
    padding-bottom: 15px;
  }
}
.contact-network {
  transition: 0.2s ease-in-out;
}
.contact-network:hover {
  transform: scale(1.2);
}
.menu {
  display: block;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 20;
}
@media screen and (max-width: 992px) {
  .menu {
    display: none;
  }
}
.menu-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #184a73;
}
.menu-content .menu-link {
  padding: 5px 0;
  text-align: center;
  letter-spacing: 5px;
  color: #fff;
  font-family: 'adam';
  font-size: 1.824rem;
}
@media screen and (max-width: 1600px) and (min-width: calc(1200px + 1px)) {
  .menu-content .menu-link {
    font-size: 1.294rem;
  }
}
@media screen and (max-width: 1200px) and (min-width: calc(992px + 1px)) {
  .menu-content .menu-link {
    font-size: 1rem;
  }
}
.menu-content .menu-link:hover {
  transition: 0.2s linear;
  color: #9a9a9a;
}
.menu-content .menu-link-active {
  color: #9a9a9a;
  font-weight: 600;
}
.menu-overlay {
  display: none;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}
@media screen and (max-width: 992px) {
  .menu-overlay {
    display: block;
  }
}
.menu-overlay-open {
  width: 100%;
}
.menu-overlay-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
}
.menu-overlay-open-btn {
  background: rgba(0, 0, 0, 0.4);
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  position: fixed;
  z-index: 50;
  top: 10px;
  right: 0;
}
.menu-overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.menu-overlay-content .menu-link {
  display: block;
  padding: 5px 0;
  letter-spacing: 5px;
  color: white;
  font-family: 'adam';
  font-size: 1.824rem;
}
.menu-icon {
  margin: 15px;
}
.download {
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  text-transform: uppercase;
  font-size: 1.118rem;
}
.download > i {
  font-size: 20px;
}
.download-primary {
  background: #2e8cd9;
  color: #fff;
  width: 200px;
  border: 0;
  transition: 0.2s ease-in-out;
}
.download-primary:hover {
  color: #fff;
  background: #1d649e;
}
.profile-container {
  background-color: transparent;
  width: 410px;
  height: 440px;
  perspective: 1000px;
}
@media screen and (max-width: 1200px) {
  .profile-container {
    width: 300px;
    height: 320px;
  }
}
@media screen and (max-width: 768px) {
  .profile-container {
    margin: auto;
  }
}
.profile-container:hover .profile-container-inner {
  transform: rotateY(180deg);
}
.profile-container-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.7s;
  transform-style: preserve-3d;
}
.profile-container-inner-front,
.profile-container-inner-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}
.profile-container-inner-back {
  background-color: #184a73;
  color: #fff;
  transform: rotateY(180deg);
}
.profile-text {
  font-size: 1.118rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}
@media screen and (max-width: 1200px) {
  .profile-text {
    font-size: 0.7647rem;
  }
}
.full-progress-bar {
  width: 100%;
  background-color: #e6e6e6;
  height: 45px;
  border-radius: 12px;
}
.full-progress-bar .progress-bar {
  background-image: linear-gradient(90deg, #184a73, #2e8cd9);
  height: 100%;
  border-radius: 12px;
  padding: 0 10px;
}
.full-progress-bar .progress-bar * {
  font-size: 1.294rem;
  color: white;
  text-transform: uppercase;
}
@media screen and (max-width: 1600px) and (min-width: calc(576px + 1px)) {
  .full-progress-bar .progress-bar * {
    font-size: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .full-progress-bar .progress-bar * {
    font-size: 0.7647rem;
  }
}
.project-container {
  position: relative;
  cursor: pointer;
}
.project-container .project-image {
  display: block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  height: auto;
}
.project-container .project-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #2e8cd9;
}
.project-container .project-overlay .project-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}
.project-container:hover .project-overlay {
  opacity: 0.95;
}
.project-container:hover p {
  animation: fadeInDown;
  animation-duration: 0.5s;
}
.project-container:hover span {
  animation: fadeInUp;
  animation-duration: 0.5s;
}
.image-gallery-icon {
  color: #fff;
  transition: all 0.2s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a);
}
@media (min-width: 768px) {
  .image-gallery-icon:hover {
    color: #337ab7;
  }
  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.1);
  }
}
.image-gallery-icon:focus {
  outline: 2px solid #337ab7;
}
.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}
.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;
}
.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 36px;
  width: 36px;
}
@media (max-width: 768px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 15px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 24px;
    width: 24px;
  }
}
@media (max-width: 480px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 10px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 16px;
    width: 16px;
  }
}
.image-gallery-fullscreen-button {
  right: 0;
}
.image-gallery-play-button {
  left: 0;
}
.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 60px;
}
@media (max-width: 768px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 72px;
    width: 36px;
  }
}
@media (max-width: 480px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 48px;
    width: 24px;
  }
}
.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  cursor: disabled;
  opacity: .6;
  pointer-events: none;
}
.image-gallery-left-nav {
  left: 0;
}
.image-gallery-right-nav {
  right: 0;
}
.image-gallery {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.image-gallery.fullscreen-modal {
  background: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}
.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
}
.image-gallery-content.fullscreen {
  background: #000;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(20vh);
}
.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}
.image-gallery-slide-wrapper {
  position: relative;
}
.image-gallery-slide-wrapper.left,
.image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(-10%);
}
@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    width: calc(13%);
  }
}
.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}
.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}
.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.image-gallery-slide.center {
  position: relative;
}
.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: contain;
}
.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
}
@media (max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    bottom: 45px;
    font-size: .8em;
    padding: 8px 15px;
  }
}
.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;
}
.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
}
.image-gallery-bullets .image-gallery-bullet {
  appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 0 1px 0 #1a1a1a;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  outline: none;
  padding: 5px;
  transition: background 0.2s ease-out;
}
@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}
@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}
.image-gallery-bullets .image-gallery-bullet:focus,
.image-gallery-bullets .image-gallery-bullet:hover {
  background: #337ab7;
  transform: scale(1.1);
}
.image-gallery-bullets .image-gallery-bullet.active {
  background: #fff;
}
.image-gallery-thumbnails-wrapper {
  position: relative;
}
.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}
.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    width: 81px;
  }
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 0;
  margin-top: 2px;
}
.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  margin: 0 5px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    margin: 0 3px;
  }
}
.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  transition: transform 0.45s ease-out;
  white-space: nowrap;
}
.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail {
    border: 3px solid transparent;
    width: 81px;
  }
}
.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  position: relative;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #337ab7;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border: 3px solid #337ab7;
  }
}
.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 1px 1px 0 black;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: .8em;
    line-height: .8em;
  }
}
.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}
@media (max-width: 768px) {
  .image-gallery-index {
    font-size: .8em;
    padding: 5px 10px;
  }
}
.image-gallery {
  height: 100%;
}
.image-gallery * {
  height: 100%;
}
.image-gallery-slides .image-gallery-image {
  min-height: 600px;
  max-height: none !important;
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(4px);
}
.image-modal .ant-modal-content {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0;
}
@media screen and (max-width: 576px) {
  .image-modal .ant-modal-content {
    margin-top: -15%;
  }
  .image-modal .ant-modal-content .ant-modal-close-x * > svg {
    fill: white;
  }
}
.image-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}
.image-modal .ant-modal-content .ant-modal-body .description-wrapper {
  background: white;
  padding: 24px;
  text-align: justify;
}
.image-modal .ant-modal-content .ant-modal-body .description-wrapper .time-wrapper {
  font-weight: 600;
  opacity: 0.5;
}
.button-link {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 1.118rem;
  padding: 10px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}
.button-link-primary {
  background: #2e8cd9 !important;
  border-radius: 6px;
  width: 200px;
  border: 0;
  color: white;
}
.button-link-primary:active,
.button-link-primary:focus {
  background: #2e8cd9 !important;
  color: white !important;
}
.button-link-primary:hover {
  color: white !important;
  background: #1d649e !important;
  transition: 0.2s linear;
}
.step-bar {
  background-image: linear-gradient(0deg, #184a73, #2e8cd9);
  width: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  align-items: center;
  z-index: 0;
  border-radius: 12px;
}
@media screen and (max-width: 768px) {
  .step-bar {
    display: none;
  }
}
.step-bar .step {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  z-index: 0;
  width: 1465px;
  margin: 20px 0;
}
.step-bar .step:hover .step-description-wrapper,
.step-bar .step:hover .step-date {
  transform: scale(1.05);
}
.step-bar .step:hover .step-description-wrapper {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 1600px) and (min-width: calc(992px - 1px)) {
  .step-bar .step {
    width: 900px;
  }
}
@media screen and (max-width: 992px) and (min-width: calc(768px - 1px)) {
  .step-bar .step {
    width: 700px;
  }
}
@media screen and (max-width: 768px) and (min-width: calc(576px - 1px)) {
  .step-bar .step {
    width: 500px;
  }
}
.step-bar .step-index {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 5px solid #f2f2f2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-bar .step-description {
  width: 50%;
}
.step-bar .step-description-wrapper {
  transition: 0.3s ease-in-out;
  background-color: #fff;
  padding: 5px 25px 15px;
}
.step-bar .step-description-left {
  padding-right: 25px;
  text-align: right;
}
@media screen and (max-width: 768px) and (min-width: calc(576px - 1px)) {
  .step-bar .step-description-left {
    padding-right: 10px;
  }
}
.step-bar .step-description-left .step-description-wrapper {
  transform-origin: right center;
}
.step-bar .step-description-right {
  padding-left: 25px;
  text-align: left;
}
@media screen and (max-width: 768px) and (min-width: calc(576px - 1px)) {
  .step-bar .step-description-right {
    padding-left: 10px;
  }
}
.step-bar .step-description-right .step-description-wrapper {
  transform-origin: left center;
}
.step-bar .step-description .step-info-left > div {
  justify-content: flex-end;
}
.step-bar .step-description .step-info-right > div {
  justify-content: flex-start;
}
.step-bar .step-description .step-title {
  font-size: 2.471rem;
  font-weight: 700;
}
.step-bar .step-description .step-subtitle {
  font-size: 1.118rem;
  color: #2e8cd9;
}
.step-bar .step-description .step-place {
  font-size: 1rem;
  font-style: italic;
}
.step-bar .step-description .step-external-link {
  font-size: 1rem;
  text-decoration: underline;
  color: #2e8cd9;
}
.step-bar .step-date {
  transition: 0.3s ease-in-out;
  width: 50%;
}
.step-bar .step-date-left {
  transform-origin: right center;
}
.step-bar .step-date-left > div {
  padding-right: 25px;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 768px) and (min-width: calc(576px - 1px)) {
  .step-bar .step-date-left > div {
    padding-right: 10px;
  }
}
.step-bar .step-date-right {
  transform-origin: left center;
}
.step-bar .step-date-right > div {
  padding-left: 25px;
  display: flex;
  justify-content: flex-start;
}
@media screen and (max-width: 768px) and (min-width: calc(576px - 1px)) {
  .step-bar .step-date-right > div {
    padding-left: 10px;
  }
}
.step-bar .step-date-wrapper {
  background-color: #2e8cd9;
  width: max-content;
  padding: 10px;
  color: white;
  position: relative;
  top: -8px;
}
@media screen and (max-width: 768px) and (min-width: calc(576px - 1px)) {
  .step-bar .step-date-wrapper {
    font-size: 0.7647rem;
  }
}
.step-bar-minimal {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
  margin-bottom: 20px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .step-bar-minimal {
    display: flex;
  }
}
.step-bar-minimal:hover .step-bar-minimal-info,
.step-bar-minimal:hover .step-bar-minimal-date {
  transform: scale(1.05);
}
.step-bar-minimal:hover .step-bar-minimal-info {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
.step-bar-minimal-date {
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
  height: 100%;
  width: 100%;
}
.step-bar-minimal-date-wrapper {
  text-align: center;
  width: 100%;
  background-color: #2e8cd9;
  padding: 20px 10px;
  color: white;
}
.step-bar-minimal-info {
  transition: 0.3s ease-in-out;
  width: 100%;
}
.step-bar-minimal-info > div {
  background-color: #fff;
  padding: 5px 25px 15px;
}
.step-bar-minimal-info-title {
  font-size: 1.294rem;
  font-weight: 700;
}
.step-bar-minimal-info-subtitle {
  font-size: 1.118rem;
  color: #2e8cd9;
}
.step-bar-minimal-info-place {
  font-size: 1rem;
  font-style: italic;
}
.arrow {
  width: 0;
  height: 0;
}
.arrow-left {
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #2e8cd9;
  margin: 0 -3px;
}
.arrow-right {
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #2e8cd9;
  margin: 0 -3px;
}
.arrow-up {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #2e8cd9;
  margin: -3px auto;
}
.arrow-down {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #2e8cd9;
  margin: -3px auto;
}
.switcher {
  display: flex;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  position: absolute;
  top: 10px;
  transition: width 0.5s;
  z-index: 1;
  border: 0;
  border-radius: 0 6px 6px 0;
}
.switcher:hover {
  width: 150px;
  background: white;
  border: 2px #2e8cd9 solid;
  color: #2e8cd9;
}
.switcher:hover .text-wrapper {
  display: block;
  transition: left 0.5s;
  left: 0;
  position: relative;
}
.switcher .flag-wrapper {
  width: 30px;
  margin: auto 10px;
  z-index: 3;
}
.switcher .text-wrapper {
  margin: auto;
  position: absolute;
  left: -100px;
  z-index: 2;
}
.year-step {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 10px 0;
}
.year-step:hover .year-step-info-wrapper,
.year-step:hover .year-step-description-wrapper {
  transform: scale(1.05);
}
.year-step:hover .year-step-info,
.year-step:hover .year-step-description {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 576px) {
  .year-step {
    display: none;
  }
}
.year-step-info {
  transition: 0.3s ease-in-out;
  padding: 10px;
  background-color: white;
  text-align: right;
}
.year-step-info-wrapper {
  transform-origin: left center;
  transition: 0.3s ease-in-out;
  width: 40%;
}
.year-step-info-title {
  font-size: 1.294rem;
  font-weight: 700;
}
.year-step-info-place {
  font-size: 1rem;
  font-style: italic;
}
.year-step-date {
  display: flex;
  align-items: center;
  height: 100%;
}
.year-step-date-wrapper {
  background-color: #2e8cd9;
  width: max-content;
  padding: 20px 10px;
  color: white;
}
.year-step-description {
  transition: 0.3s ease-in-out;
  background-color: white;
  padding: 10px;
}
.year-step-description-wrapper {
  transform-origin: right center;
  transition: 0.3s ease-in-out;
  width: 40%;
}
.year-step-minimal {
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  margin: 10px 0;
}
@media screen and (max-width: 576px) {
  .year-step-minimal {
    display: flex;
  }
}
.year-step-minimal:hover .year-step-minimal-info,
.year-step-minimal:hover .year-step-minimal-description {
  transform: scale(1.05);
}
.year-step-minimal:hover .year-step-minimal-info {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
.year-step-minimal-info {
  transition: 0.3s ease-in-out;
  width: 100%;
  text-align: right;
}
.year-step-minimal-info > div {
  background-color: white;
  padding: 10px;
}
.year-step-minimal-info-title {
  font-size: 1.294rem;
  font-weight: 700;
}
.year-step-minimal-info-place {
  font-size: 1rem;
  font-style: italic;
}
.year-step-minimal-date {
  display: flex;
  margin: 15px 0;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.year-step-minimal-date-wrapper {
  background-color: #2e8cd9;
  width: max-content;
  padding: 20px 10px;
  color: white;
}
.year-step-minimal-description {
  transition: 0.3s ease-in-out;
  width: 100%;
}
.year-step-minimal-description > div {
  background-color: white;
  padding: 10px;
}
